import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {Contact} from "../models/Contact";

export const ContactService = {
    getAvailableByCurrentCompany: async (): Promise<ApiResponse<Contact[] | ApiErrorData>> => {
        try {
            const response = await MainBackendAxios.get(`/contact/get-available-by-current-company`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
}
