import React, {useEffect, useState} from 'react';
import {Alert, Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Select} from "antd";
import {Menu} from "../../../../models/Menu";
import {REPORT_TYPES_ITEMS} from "../../../../utils/Constants";
import {MenuType} from "../../../../config/Config";
import TextArea from "antd/es/input/TextArea";
import {FolderOpenOutlined} from "@ant-design/icons";
import ReportFileUploader, {CustomReportFileUpload} from "./ReportFileUploader";
import {FileHelper} from "../../../../utils/FileHelper";

interface NewReportModalProps {
    selectedNode: Menu | null;
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (
        menuType: MenuType.IFRAME_VIEWER | MenuType.POWER_BI_REPORT | MenuType.PDF_VIEWER | MenuType.OFFICE_VIEWER,
        name: string, link: string | null,
        parentMenuCode: string | null,
        additionalConfig: object,
        uploadFile: CustomReportFileUpload | null
    ) => void;
}

export const REPORT_PDF_MAX_SIZE = 50 * 1024 * 1024;//50 MB
export const REPORT_OFFICE_MAX_SIZE = 50 * 1024 * 1024;//50 MB

function NewReportModal({isOpen, handleModalCancel, submit, selectedNode}: NewReportModalProps) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState<MenuType.IFRAME_VIEWER | MenuType.POWER_BI_REPORT | MenuType.PDF_VIEWER | MenuType.OFFICE_VIEWER>();
    const [pdfUploadFile, setPdfUploadFile] = useState<CustomReportFileUpload | null>(null);
    const [officeUploadFile, setOfficeUploadFile] = useState<CustomReportFileUpload | null>(null);

    useEffect(() => {
        if(isOpen) {
            form.resetFields();
            setSelectedType(undefined);
        }
    }, [isOpen]);

    useEffect(() => {
        form.setFieldValue('showFilterPane', true);
        form.setFieldValue('showPageNavigation', true);
        form.setFieldValue('link', '');
        form.setFieldValue('reportId', '');
        form.setFieldValue('groupId', '');
        setPdfUploadFile(null);
        setOfficeUploadFile(null);
    }, [selectedType]);

    const onFinish = async (values: any) => {
        if(selectedType) {
            setLoading(true);

            await submit(
                selectedType,
                values.title.trim(),
                values.link ? values.link : null,
                selectedNode ? selectedNode.code : null,
                selectedType === MenuType.POWER_BI_REPORT
                                ? { settings: { showFilterPane: !!values.showFilterPane, showPageNavigation: !!values.showPageNavigation }, groupId: values.groupId, reportId: values.reportId }
                                :  {},
                selectedType === MenuType.OFFICE_VIEWER
                            ? officeUploadFile
                            : selectedType === MenuType.PDF_VIEWER
                                ? pdfUploadFile
                                : null

            );

            setLoading(false);
        }
    }

    return (
        <Modal
            title="Añadir reporte"
            open={isOpen}
            onCancel={handleModalCancel}
            maskClosable={false}
            destroyOnClose
            footer={null}
            width={480}
        >
            <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={24} style={{ marginBottom: '18px' }}>
                    <Col xs={24}>
                        <Alert message="Al generarse el nuevo reporte se añadirá directamente al contenedor padre." type="info" showIcon />
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} lg={24}>
                        <Form.Item
                            label="Contenedor padre"
                        >
                            <Input addonBefore={<FolderOpenOutlined />} type="text" placeholder="Contenedor" readOnly defaultValue={selectedNode ? selectedNode.title : `${window.location.origin}`} disabled={loading}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider style={{ marginTop: '0', marginBottom: '15px' }}/>

                <Row gutter={24}>
                    <Col xs={24} lg={24}>
                        <Form.Item
                            name="title"
                            label="Título"
                            rules={[{ required: true, message: 'Debes de ingresar el título' }]}
                        >
                            <Input type="text" disabled={loading} placeholder="Ingrese el título"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} lg={24}>
                        <Form.Item
                            name="type"
                            label="Tipo de reporte"
                            rules={[{ required: true, message: 'Debes de seleccionar una opción' }]}
                        >
                            <Select
                                placeholder="Seleccione una opción"
                                options={REPORT_TYPES_ITEMS}
                                allowClear={true}
                                onChange={(newValue) => {
                                    setSelectedType(newValue);
                                }}
                                disabled={loading}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {
                    selectedType === MenuType.IFRAME_VIEWER && (
                        <Row gutter={24}>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    name="link"
                                    label="Enlace url"
                                    rules={[
                                        { required: true, message: 'Debes de ingresar el enlace url' },
                                        {
                                            validator: (_, value) => {
                                                if (!value) return Promise.resolve();
                                                const urlRegex = /^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w-.]*)*(\?.*)?(#.*)?$/;
                                                if (!urlRegex.test(value)) {
                                                    return Promise.reject(new Error('Debes ingresar un enlace válido que comience con http:// o https://'));
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder="Ingrese el enlace url del reporte"
                                        rows={4}
                                        disabled={loading}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }

                {
                    selectedType === MenuType.POWER_BI_REPORT && (
                        <>
                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        name="reportId"
                                        label="ID del reporte"
                                        rules={[{ required: true, message: 'Debes de ingresar el ID del reporte' }]}
                                    >
                                        <Input type="text" placeholder="Ingrese el ID del reporte" disabled={loading}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        name="groupId"
                                        label="ID del grupo o espacio de trabajo"
                                        rules={[{ required: true, message: 'Debes de ingresar el ID del grupo o espacio de trabajo' }]}
                                    >
                                        <Input type="text" placeholder="Ingrese el ID del grupo o espacio de trabajo" disabled={loading}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        name="showFilterPane"
                                        valuePropName="checked"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Checkbox disabled={loading}>Deseo mostrar el panel de filtros del reporte.</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        name="showPageNavigation"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={loading}>Deseo habilitar la navegación entre páginas del reporte.</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )
                }

                {
                    selectedType === MenuType.PDF_VIEWER && (
                        <>
                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        name="pdfFile"
                                        label="Archivo"
                                        rules={[
                                            {
                                                required: true,
                                                validator: (_, value) => {
                                                    if (pdfUploadFile) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Debes cargar un archivo.'));
                                                },
                                            }
                                        ]}
                                    >
                                        <ReportFileUploader
                                            renameFile
                                            allowedTypes={[
                                                'application/pdf'
                                            ]}
                                            allowedTypeErrorMessage="Solo se permiten archivos de tipo pdf."
                                            helpFileText={`Máximo de ${FileHelper.formatFileSize(REPORT_PDF_MAX_SIZE, true)}. Solo se permiten archivos de tipo pdf.`}
                                            maxSizeInBytes={REPORT_PDF_MAX_SIZE}
                                            uploadedFile={pdfUploadFile}
                                            setUploadFile={setPdfUploadFile}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )
                }

                {
                    selectedType === MenuType.OFFICE_VIEWER && (
                        <>
                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        name="officeFile"
                                        label="Archivo"
                                        rules={[
                                            {
                                                required: true,
                                                validator: (_, value) => {
                                                    if (officeUploadFile) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Debes cargar un archivo.'));
                                                },
                                            }
                                        ]}
                                    >
                                        <ReportFileUploader
                                            renameFile
                                            allowedTypes={[
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                'application/vnd.ms-excel',
                                                'application/msword',
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                'application/vnd.ms-powerpoint',
                                                'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                                            ]}
                                            allowedTypeErrorMessage="Solo se permiten archivos de tipo xlsx, xls, doc, docx, ppt o pptx."
                                            helpFileText={`Máximo de ${FileHelper.formatFileSize(REPORT_OFFICE_MAX_SIZE, true)}. Solo se permiten archivos de tipo xlsx, xls, doc, docx, ppt o pptx.`}
                                            maxSizeInBytes={REPORT_OFFICE_MAX_SIZE}
                                            uploadedFile={officeUploadFile}
                                            setUploadFile={setOfficeUploadFile}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )
                }

                <Row gutter={24}>
                    <Col xs={24}>
                        <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default NewReportModal;
