export const FileHelper = {
    getFileExtension: (filename: string) => {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    },
    formatFileSize: (bytes: number, spacing = false): string => {
        if (bytes === 0) return `0${spacing ? ' ': ''}B`;

        const sizes = ["B", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        const formattedSize = bytes / Math.pow(1024, i);

        // Redondear a 2 decimales si no es un número entero
        const finalSize = formattedSize % 1 === 0 ? formattedSize.toFixed(0) : formattedSize.toFixed(2);

        return `${finalSize}${spacing ? ' ': ''}${sizes[i]}`;
    },
    getFilenameFromS3Key: (s3Key: string): string => {
        const parts = s3Key.split('/');
        return parts[parts.length - 1];
    }
}
