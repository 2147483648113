import { theme, ThemeConfig } from "antd";
import { useSelector } from "react-redux";
import { CustomConfig } from "../models/CustomConfig";
import { DashboardConfig } from "../models/DashboardConfig";

export const useThemeLayoutConfig = (): { theme: ThemeConfig; customValues: CustomConfig } => {
    const { token: themeToken } = theme.useToken(); // Extrae solo los tokens del tema actual
    const dashboardConfig: DashboardConfig = useSelector((state: any) => state.dashboardConfig) || {};

    // Asegúrate de que dashboardConfig tenga valores predeterminados para evitar errores
    const {
        primaryColor = themeToken.colorPrimary,
        menuItemSelectedBg = themeToken.colorPrimaryBg,
        menuItemActiveBg = themeToken.colorPrimaryActive,
        menuItemSelectedColor = themeToken.colorText,
        secondaryColor = themeToken.colorTextSecondary,
    } = dashboardConfig;

    // Configuración personalizada del tema
    const customThemeConfig: ThemeConfig = {
        token: {
            ...themeToken, // Incluye todos los tokens existentes
            colorPrimary: primaryColor,
        },
        components: {
            Menu: {
                itemSelectedBg: menuItemSelectedBg,
                itemActiveBg: menuItemActiveBg,
                itemSelectedColor: menuItemSelectedColor,
            },
            Layout: {
                triggerBg: primaryColor,
            },
        },
    };

    return {
        theme: customThemeConfig,
        customValues: {
            secondaryColor,
        },
    };
};
