import React from 'react';
import {UserOutlined, PhoneOutlined, MailOutlined, WhatsAppOutlined} from '@ant-design/icons';
import { Avatar, theme } from 'antd';
import {Contact} from "../../../models/Contact";
import {useThemeLayoutConfig} from "../../../config/ThemeDashboardLayoutConfig";
import defaultAvatar from '../../../assets/images/avatar.png';
import {MAIN_BACKEND_URL} from "../../../config/Config";

import './ContactCard.scss';

interface ContactCardProps {
    contact: Contact;
}

export const ContactCard: React.FC<ContactCardProps> = ({ contact }) => {
    const { theme, customValues } = useThemeLayoutConfig();
    const phone = `${contact.dialCode}${contact.phone}`;
    const whatsappLink = `https://wa.me/${phone.replace(/[^0-9]/g, '')}`;
    const avatarUrl = contact.avatar
        ? `${MAIN_BACKEND_URL}/file/get?key=${contact.avatar}`
        : defaultAvatar;

    const styles = {
        card: {
            '--contact-primary-color': theme?.token?.colorPrimary,
            '--contact-secondary-color': customValues?.secondaryColor,
            '--contact-primary-hover': theme?.components?.Menu?.itemSelectedBg,
            '--contact-bg-container': theme?.token?.colorBgContainer,
            '--contact-bg-elevated': theme?.token?.colorBgElevated,
            '--contact-text-primary': theme?.token?.colorTextHeading,
            '--contact-text-secondary': theme?.token?.colorTextSecondary,
            '--contact-border-radius': `${(theme?.token?.borderRadiusLG || 0) * 2}px`,
            '--contact-border-radius-sm': `${theme?.token?.borderRadius}px`,
        } as React.CSSProperties,
    };

    return (
        <div className="contact-card" style={styles.card}>
            <div className="contact-content">
                <div className="contact-avatar">
                    <Avatar
                        size={100}
                        src={avatarUrl}
                        icon={<UserOutlined />}
                        style={{ backgroundColor: 'white' }}
                    />
                </div>
                <div className="contact-info">
                    <div className="contact-header">
                        <h3>{contact.fullName}</h3>
                        <span className="position-badge">{contact.position}</span>
                    </div>
                    <div className="contact-actions">
                        <a
                            href={whatsappLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-detail"
                            title="Enviar mensaje por WhatsApp"
                        >
                            <PhoneOutlined className="icon" />
                            {/*<WhatsAppOutlined className="icon" />*/}
                            <span>{phone}</span>
                        </a>
                        <a
                            href={`mailto:${contact.email}`}
                            className="contact-detail"
                            title="Enviar correo electrónico"
                        >
                            <MailOutlined className="icon" />
                            <span>{contact.email}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
