import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {RcFile} from "antd/es/upload/interface";

export const FileService = {
    createTemporalFile: async (params: CreateTemporalFileRequestDto): Promise<ApiResponse<CreateTemporalFileResponseDto | ApiErrorData>> => {
        try {
            const formData = new FormData();
            formData.append('file', params.file);

            if(params.name) {
                formData.append('name', params.name);
            }

            if(params.maxSizeInBytes) {
                formData.append('maxSizeInBytes', `${params.maxSizeInBytes}`);
            }

            if(params.contentTypesAllowed && params.contentTypesAllowed.length > 0) {
                for(const contentTypeAllowed of params.contentTypesAllowed) {
                    formData.append('contentTypesAllowed[]', contentTypeAllowed);
                }
            }

            const response = await MainBackendAxios.post(`/file/upload-temporal-file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
}

interface CreateTemporalFileRequestDto {
    file: RcFile | File;
    name?: string;
    contentTypesAllowed?: string[];
    maxSizeInBytes?: number;
}

export interface CreateTemporalFileResponseDto {
    s3Key: string;
    size: number;
}
