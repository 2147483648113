import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {User} from "../../models/User";
import {useDispatch, useSelector} from "react-redux";
import {
    DEFAULT_DASHBOARD_LOGO_HEIGHT,
    DEFAULT_DASHBOARD_LOGO_URL,
    DEFAULT_DASHBOARD_MENU_ITEM_ACTIVE_BG,
    DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_BG,
    DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_COLOR,
    DEFAULT_PRIMARY_COLOR,
    DEFAULT_SECONDARY_COLOR,
    USER_TYPES
} from "../../config/Constants";
import {
    Avatar,
    Button, ConfigProvider,
    Drawer,
    Flex,
    FloatButton,
    Form,
    GetProp,
    Input,
    message,
    Space,
    Upload,
    UploadProps,
    Layout as AntdLayout, Menu, UploadFile,
} from "antd";
import {
    GithubOutlined,
    HomeOutlined,
    OpenAIOutlined,
    SettingOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {useThemeLayoutConfig} from "../../config/ThemeDashboardLayoutConfig";
import {DashboardConfig} from "../../models/DashboardConfig";
import DashboardConfigAction from "../../redux/actions/dashboardConfigActions/Action";
import {Header} from "antd/es/layout/layout";
import {CompanyService, UpdateCustomConfigByCurrentUserRequestDto} from "../../services/CompanyService";
import {ApiErrorData} from "../../models/ApiResponse";

interface ConfigSettingsProps {
    hideFloatButton?: boolean;
}

export interface ConfigSettingsHandle {
    openDrawer: () => void;
    closeDrawer: () => void;
}

const ConfigSettings = forwardRef<ConfigSettingsHandle, ConfigSettingsProps>(({ hideFloatButton = false }, ref) => {
    const customConfig = useThemeLayoutConfig();
    const dashboardConfig: DashboardConfig = useSelector((state: any) => state.dashboardConfig);
    const authUser: User = useSelector((state: any) => state.auth);
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingLogoUpload, setLoadingLogoUpload] = useState(false);
    const [form] = Form.useForm();
    const [primaryColor, setPrimaryColor] = useState(DEFAULT_PRIMARY_COLOR);
    const [secondaryColor, setSecondaryColor] = useState(DEFAULT_SECONDARY_COLOR);
    const [logoUrl, setLogoUrl] = useState(DEFAULT_DASHBOARD_LOGO_URL);
    const [logoHeight, setLogoHeight] = useState(DEFAULT_DASHBOARD_LOGO_HEIGHT);
    const [itemSelectedBg, setItemSelectedBg] = useState(DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_BG);
    const [itemActiveBg, setItemActiveBg] = useState(DEFAULT_DASHBOARD_MENU_ITEM_ACTIVE_BG);
    const [itemSelectedColor, setItemSelectedColor] = useState(DEFAULT_DASHBOARD_MENU_ITEM_SELECTED_COLOR);

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    useImperativeHandle(ref, () => ({
        openDrawer: showDrawer,
        closeDrawer: closeDrawer
    }));

    useEffect(() => {
        if(isOpen) {
            form.resetFields();
            setPrimaryColor(dashboardConfig.primaryColor);
            setSecondaryColor(dashboardConfig.secondaryColor);
            setLogoUrl(dashboardConfig.logoUrl);
            setLogoHeight(dashboardConfig.logoHeight);
            setItemSelectedBg(dashboardConfig.menuItemSelectedBg);
            setItemActiveBg(dashboardConfig.menuItemActiveBg);
            setItemSelectedColor(dashboardConfig.menuItemSelectedColor);
            form.setFieldValue('primaryColor', dashboardConfig.primaryColor);
            form.setFieldValue('secondaryColor', dashboardConfig.secondaryColor);
            form.setFieldValue('itemSelectedBg', dashboardConfig.menuItemSelectedBg);
            form.setFieldValue('itemActiveBg', dashboardConfig.menuItemActiveBg);
            form.setFieldValue('itemSelectedColor', dashboardConfig.menuItemSelectedColor);
            setFileList([]);
        }
    }, [isOpen]);

    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setFileList([]);
            setLogoUrl(dashboardConfig.logoUrl);
            messageApi.error('Sólo puedes subir archivos JPG/PNG!');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setFileList([]);
            setLogoUrl(dashboardConfig.logoUrl);
            messageApi.error('¡La imagen debe tener un tamaño inferior a 2 MB!');
            return false;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setLogoUrl(reader.result as string);
        };
        reader.readAsDataURL(file);

        setFileList([file]);
        return false;
    };

    const showDrawer = () => {
        if(!loading) {
            setIsOpen(true);
        }
    }

    const closeDrawer = () => {
        if(!loading) {
            setIsOpen(false);
        }
    }

    const onFinishSettingsForm = async (values: any) => {
        if(!loading) {
            const bodyParams: UpdateCustomConfigByCurrentUserRequestDto = {
                primaryColor: values.primaryColor,
                secondaryColor: values.secondaryColor,
                itemSelectedBg: values.itemSelectedBg,
                itemActiveBg: values.itemSelectedBg,//No tiene config actual
                itemSelectedColor: values.itemSelectedColor,
                logoHeight: values.logoHeight || undefined
            };

            setLoading(true);

            const updateCustomConfigResponse = await CompanyService.updateCustomConfigByCurrentUser(bodyParams, fileList.length > 0 ? fileList[0] as any : undefined);

            if(updateCustomConfigResponse.success) {
                messageApi.success('Se guardaron los cambios satisfactoriamente.', 3.5);

                if(fileList.length > 0) {
                    dispatch(DashboardConfigAction.update({
                        primaryColor: values.primaryColor,
                        secondaryColor: values.secondaryColor,
                        menuItemSelectedBg: values.itemSelectedBg,
                        menuItemActiveBg: values.itemSelectedBg,
                        menuItemSelectedColor: values.itemSelectedColor,
                        logoUrl: logoUrl
                    }));
                }else {
                    dispatch(DashboardConfigAction.update({
                        primaryColor: values.primaryColor,
                        secondaryColor: values.secondaryColor,
                        menuItemSelectedBg: values.itemSelectedBg,
                        menuItemActiveBg: values.itemSelectedBg,
                        menuItemSelectedColor: values.itemSelectedColor
                    }));
                }
                setLoading(false);
                closeDrawer();
            }else {
                setLoading(false);
                const error = updateCustomConfigResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar guardar la configuración, por favor inténtalo nuevamente.', 3.5);
            }
        }
    };

    const onFinishFailedSettingsForm = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };

    const uploadProps: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    return (
        <>
            {contextHolder}
            {
                authUser.userType === USER_TYPES.SUPER_ADMIN && (
                    <>
                        {
                            !hideFloatButton && (
                                <FloatButton icon={<SettingOutlined/>} type="primary" onClick={() => { showDrawer(); } } />
                            )
                        }

                        <Drawer
                            closable={false}
                            title={(
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span style={{ color: 'white' }}><SettingOutlined/> Configuración</span>
                                </div>
                            )}
                            width={360}
                            onClose={() => { closeDrawer(); }}
                            open={isOpen}
                            styles={{
                                body: {
                                    paddingBottom: 80,
                                },
                            }}
                            footer={(
                                <ConfigProvider theme={{
                                    token: {
                                        colorPrimary: primaryColor
                                    }
                                }}>
                                    <Flex justify="flex-start">
                                        <Button type="primary" loading={loading} onClick={() => { form.submit(); }} style={{ width: '100%' }}>
                                            GUARDAR CAMBIOS
                                        </Button>
                                    </Flex>
                                </ConfigProvider>
                            )}
                            headerStyle={{
                                backgroundColor: primaryColor || 'white'
                            }}
                            destroyOnClose
                        >
                            <Form
                                name="custom-settings-form"
                                onFinish={onFinishSettingsForm}
                                onFinishFailed={onFinishFailedSettingsForm}
                                layout="vertical"
                                form={form}
                            >
                                <Form.Item
                                    label="Cliente"
                                >
                                    <Input
                                        placeholder="Cliente"
                                        value={authUser.company.name}
                                        style={{ fontWeight: 'bold' }}
                                        disabled={true}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="primaryColor"
                                    label="Color primario"
                                >
                                    <Input
                                        placeholder="Color primario"
                                        type="color"
                                        onChange={(v) => {
                                            setPrimaryColor(v.target.value);
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="secondaryColor"
                                    label="Color secundario"
                                >
                                    <Input
                                        placeholder="Color secundario"
                                        type="color"
                                        onChange={(v) => {
                                            setSecondaryColor(v.target.value);
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="itemSelectedBg"
                                    label="Color selección menú"
                                >
                                    <Input
                                        placeholder="Color selección menú"
                                        type="color"
                                        onChange={(v) => {
                                            setItemSelectedBg(v.target.value);
                                            setItemActiveBg(v.target.value);
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="itemSelectedColor"
                                    label="Color letras menú seleccionado"
                                >
                                    <Input
                                        placeholder="Color letras menú seleccionado"
                                        type="color"
                                        onChange={(v) => {
                                            setItemSelectedColor(v.target.value);
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Logo"
                                    name="logo"
                                >
                                    <Upload {...uploadProps} showUploadList={false} beforeUpload={beforeUpload} fileList={fileList}>
                                        <Button icon={<UploadOutlined />}>{ fileList.length === 0 ? 'Selecciona una imagen' : 'Selecciona otra imagen' }</Button>
                                    </Upload>
                                </Form.Item>

                                <Form.Item label="Vista previa">
                                    <ConfigProvider theme={{
                                        token: {
                                            colorPrimary: primaryColor
                                        },
                                        components: {
                                            Menu: {
                                                itemSelectedBg: itemSelectedBg,
                                                itemActiveBg: itemActiveBg,
                                                itemSelectedColor: itemSelectedColor
                                            }
                                        }
                                    }}>
                                        <AntdLayout style={{ minHeight: '200px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)', borderRadius: '4px' }}>
                                            <Header className="activation-header" style={{ backgroundColor: primaryColor }}>
                                                <Flex gap="middle" justify="space-between" align="center" style={{ width: '100%' }}>
                                                    <img
                                                        className="logo"
                                                        src={logoUrl}
                                                        style={{ height: `${logoHeight}px` }}
                                                    />

                                                    <Space direction="vertical">
                                                        <Space wrap>
                                                            <Space size={16} wrap>
                                                                <Avatar style={{ backgroundColor: secondaryColor || '#cfe8fc', color: primaryColor || '#0077cc', cursor: 'pointer' }}><span style={{ textTransform: 'uppercase' }}>{ authUser.name.slice(0, 1) + authUser.lastname.slice(0, 1) }</span></Avatar>
                                                            </Space>
                                                        </Space>
                                                    </Space>
                                                </Flex>
                                            </Header>

                                            <Menu
                                                mode="inline"
                                                style={{ height: '100%', borderRight: 0, padding: '4px 4px' }}
                                                selectable={false}
                                                defaultSelectedKeys={['custom-2', 'custom-2-1']}
                                                openKeys={['custom-2']}
                                            >
                                                <Menu.Item key="custom-1" icon={<HomeOutlined/>}>Home</Menu.Item>
                                                <Menu.SubMenu title="OpenAI" key="custom-2" icon={<OpenAIOutlined />}>
                                                    <Menu.Item key="custom-2-1">
                                                        ChatGPT
                                                    </Menu.Item>
                                                </Menu.SubMenu>
                                                <Menu.Item key="custom-3" icon={<GithubOutlined />}>Github</Menu.Item>
                                            </Menu>
                                        </AntdLayout>
                                    </ConfigProvider>
                                </Form.Item>
                            </Form>
                        </Drawer>
                    </>
                )
            }
        </>
    );
});

export default ConfigSettings;
