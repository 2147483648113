import React from 'react';
import { ContactCard } from './ContactCard';
import {Contact} from "../../../models/Contact";
import {Card, Result, Skeleton, theme} from 'antd';
import {useThemeLayoutConfig} from "../../../config/ThemeDashboardLayoutConfig";

import './ContactGrid.scss';

interface ContactGridProps {
    contacts: Contact[];
    loading?: boolean;
}

const LoadingSkeleton: React.FC = () => {
    const skeletonCards = Array(3).fill(null);

    return (
        <>
            {skeletonCards.map((_, index) => (
                <Card
                    key={index}
                    className="contact-card-skeleton"
                >
                    <div className="skeleton-content">
                        <div className="skeleton-avatar">
                            <Skeleton.Avatar active size={100} />
                        </div>
                        <Skeleton active paragraph={{ rows: 3 }} />
                    </div>
                </Card>
            ))}
        </>
    );
};

export const ContactGrid: React.FC<ContactGridProps> = ({ contacts, loading = false }) => {
    const { theme } = useThemeLayoutConfig();

    const styles = {
        emptyState: {
            '--contact-primary-color': theme?.token?.colorPrimary,
            '--contact-bg-container': theme?.token?.colorBgContainer,
            '--contact-text-primary': theme?.token?.colorTextHeading,
            '--contact-text-secondary': theme?.token?.colorTextSecondary,
            '--contact-border-radius': `${theme?.token?.borderRadiusLG}px`,
        } as React.CSSProperties,
    };

    if (loading) {
        return (
            <div className="contact-grid">
                <LoadingSkeleton />
            </div>
        );
    }

    if (contacts.length === 0) {
        return (
            <div className="contact-grid">
                <div className="empty-state" style={styles.emptyState}>
                    <Result
                        status="404"
                        title="No hay contactos disponibles"
                        subTitle="Parece que aún no hay contactos en tu lista. Cuando se configuren los contactos, aparecerán aquí y podrás verlos fácilmente."
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="contact-grid">
            {contacts.map((contact) => (
                <ContactCard key={contact.id} contact={contact} />
            ))}
        </div>
    );
};
