export const REACT_APP_NAME =
  process.env.REACT_APP_NAME || 'customer-portal-touchlatam';
export const MAIN_BACKEND_URL =
  process.env.REACT_APP_MAIN_BACKEND_URL || 'someapi.com';
export const APP_LANGUAGE = process.env.REACT_APP_LANGUAGE || 'en';
export const ENV = process.env.NODE_ENV || 'development';

export enum MenuType {
    STANDARD_MENU = "STANDARD_MENU",
    IFRAME_VIEWER = "IFRAME_VIEWER",
    POWER_BI_REPORT = "POWER_BI_REPORT",
    PARENT_MENU = "PARENT_MENU",
    URL_REDIRECT = "URL_REDIRECT",
    PDF_VIEWER = "PDF_VIEWER",
    OFFICE_VIEWER = "OFFICE_VIEWER",
}
