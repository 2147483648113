import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {Card, Dropdown, MenuProps, message, Modal, Tooltip, Tree, TreeDataNode, TreeProps,} from "antd";
import {
    AreaChartOutlined,
    CopyrightOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    FileOutlined,
    FolderOutlined,
    GlobalOutlined,
} from "@ant-design/icons";
import {Menu} from "../../../models/Menu";
import {MenuService, ReorganizeCompanyMenusRequestDto} from "../../../services/MenuService";
import {ApiErrorData} from "../../../models/ApiResponse";
import {User} from "../../../models/User";
import {useSelector} from "react-redux";
import NewContainerModal from "./Components/NewContainerModal";
import NewReportModal, {REPORT_OFFICE_MAX_SIZE, REPORT_PDF_MAX_SIZE} from "./Components/NewReportModal";
import {useThemeLayoutConfig} from "../../../config/ThemeDashboardLayoutConfig";
import DynamicIcon from "../../../components/DynamicIcon/DynamicIcon";
import DetailContainerModal from "./Components/DetailContainerModal";
import DetailReportModal from "./Components/DetailReportModal";

import './ReportAndMenus.scss';
import {MenuType} from "../../../config/Config";
import {CustomReportFileUpload} from "./Components/ReportFileUploader";
import {UploadFile} from "antd/es/upload/interface";
import {CreateTemporalFileResponseDto, FileService} from "../../../services/FileService";

interface FlattenedNode {
    code: React.Key;
    name: string;
    breadcrumb: string;
    order: number;
    parentCode: React.Key | null;
}

const buildTree = (data: Menu[], companyId: number, color = '#f5222d') => {
    const map:any = {};
    const tree: TreeDataNode[] = [];

    data.forEach(item => {
        map[item.code] = {
            title: (
                <span>
                    {item.title}
                    {' '}
                    {
                        (item.companyOwnerId === companyId)
                            ? (
                                <Tooltip title="El elemento está asociado a la compañía del usuario, asi que tiene acceso a eliminarlo si así lo desea.">
                                    <CopyrightOutlined style={{ color: color }} />
                                </Tooltip>
                            )
                            : undefined
                    }
                </span>
            ),
            key: item.code,
            icon: item.menuTypeId === 'PARENT_MENU' ? /*<FolderOpenOutlined />*/ (item.icon ? <DynamicIcon type={item.icon.trim()}/> : <DynamicIcon type={'SwitcherOutlined'}/>)  : <FileOutlined />,
            children: []
        };
    });

    data.forEach(item => {
        if (item.parentMenuCode) {
            map[item.parentMenuCode].children.push(map[item.code]);
        } else {
            tree.push(map[item.code]);
        }
    });

    return tree;
};

const ROOT_CODE = 'virtual_root';

const INIT_TREE_ROOT = {
    title: <span style={{ fontWeight: 550 }}>{window.location.origin}</span>,
    key: ROOT_CODE,
    icon: <GlobalOutlined />,
    children: []
};

function ReportAndMenus() {
    const [modal, contextModalHolder] = Modal.useModal();
    const [messageApi, contextHolder] = message.useMessage();
    const customConfig = useThemeLayoutConfig();
    const authUser: User = useSelector((state: any) => state.auth);
    const [loading, setLoading] = useState(true);
    const [treeLoading, setTreeLoading] = useState(true);
    const [dragEnable, setDragEnable] = useState(true);
    const [dataSource, setDataSource] = useState<Menu[]>([]);

    const [isShowNewParentMenuModal, setIsShowNewParentMenuModal] = useState(false);
    const [isShowNewReportMenuModal, setIsShowNewReportMenuModal] = useState(false);
    const [isShowDetailParentMenuModal, setIsShowDetailParentMenuModal] = useState(false);
    const [isShowDetailReportMenuModal, setIsShowDetailReportMenuModal] = useState(false);
    const [treeDataSource, setTreeDataSource] = useState<TreeDataNode[]>([INIT_TREE_ROOT]);
    const [treeBackupDataSource, setTreeBackupDataSource] = useState<TreeDataNode[]>([INIT_TREE_ROOT]);
    const [parentTitle, setParentTitle] = useState('');

    const [selectedNode, setSelectedNode] = useState<Menu>();

    const [consoleMessages, setConsoleMessages] = useState<string[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        await fetchDataFromServer(true);
        setLoading(false);
    }

    const addConsoleMessage = (message: string) => {
        setConsoleMessages((prevMessages) => [...prevMessages, message]);
    };

    const openNewParentMenuModal = () => {
        setIsShowNewParentMenuModal(true);
    }

    const handleCloseNewParentMenuModal = () => {
        setIsShowNewParentMenuModal(false);
    }

    const openNewReportMenuModal = (menu: Menu | null) => {
        setSelectedNode(menu || undefined);
        setIsShowNewReportMenuModal(true);
    }

    const handleCloseNewReportMenuModal = () => {
        setIsShowNewReportMenuModal(false);
    }

    const openDetailParentMenuModal = (menu: Menu | null) => {
        setSelectedNode(menu || undefined);
        setIsShowDetailParentMenuModal(true);
    }

    const handleCloseDetailParentMenuModal = () => {
        setIsShowDetailParentMenuModal(false);
    }

    const openDetailReportMenuModal = (menu: Menu | null, parentName: string) => {
        setSelectedNode(menu || undefined);
        setParentTitle(parentName);
        setIsShowDetailReportMenuModal(true);
    }

    const handleCloseDetailReportMenuModal = () => {
        setIsShowDetailReportMenuModal(false);
    }

    const createNewParentMenu = async (title: string, icon: string | null) => {
        setTreeLoading(true);

        const response = await MenuService.createForCurrentCompany({
            title,
            icon,
            menuType: MenuType.PARENT_MENU,
            parentMenuCode: null,
            redirectLink: null,
            additionalConfig: {}
        });

        if(response.success) {
            await fetchDataFromServer();
            messageApi.info(`Se añadió el contenedor de manera satisfactoria.`);
            handleCloseNewParentMenuModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar añadir el contenedor.', 3.5);
        }

        setTreeLoading(false);
    }

    const updateParentMenu = async (code: string, title: string, icon: string | null) => {
        setTreeLoading(true);

        const response = await MenuService.updateForCurrentCompany(code, {
            title,
            icon,
            redirectLink: null,
            additionalConfig: {}
        });

        if(response.success) {
            await fetchDataFromServer();
            messageApi.info(`Se actualizó el contenedor de manera satisfactoria.`);
            handleCloseDetailParentMenuModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar actualizar el contenedor.', 3.5);
        }

        setTreeLoading(false);
    }

    const updateReportMenu = async (
        code: string,
        menuType: MenuType.IFRAME_VIEWER | MenuType.POWER_BI_REPORT | MenuType.PDF_VIEWER | MenuType.OFFICE_VIEWER,
        title: string,
        link: string | null,
        additionalConfig: {},
        uploadedFile: CustomReportFileUpload | null
    ) => {
        setTreeLoading(true);

        if(uploadedFile && uploadedFile.file && [MenuType.PDF_VIEWER, MenuType.OFFICE_VIEWER].includes(menuType)) {
            const responseUploadFile = await FileService.createTemporalFile({
                file: uploadedFile.file.originFileObj!,
                name: uploadedFile.name,
                maxSizeInBytes: MenuType.OFFICE_VIEWER ? REPORT_PDF_MAX_SIZE : REPORT_OFFICE_MAX_SIZE
            });

            if(responseUploadFile.success) {
                const responseUploadFileData = responseUploadFile.data as CreateTemporalFileResponseDto;
                additionalConfig = {
                    file: responseUploadFileData.s3Key,
                    size: responseUploadFileData.size
                };

            }else {
                const error = responseUploadFile.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar cargar el archivo al servidor.', 3.5);
                setTreeLoading(false);
                return;
            }
        }

        const response = await MenuService.updateForCurrentCompany(code, {
            title,
            icon: null,
            redirectLink: link,
            menuType,
            additionalConfig
        });

        if(response.success) {
            await fetchDataFromServer();
            messageApi.info(`Se actualizó el reporte de manera satisfactoria.`);
            handleCloseDetailReportMenuModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar actualizar el reporte.', 3.5);
        }

        setTreeLoading(false);
    }

    const createNewReportMenu = async (
        menuType: MenuType.IFRAME_VIEWER | MenuType.POWER_BI_REPORT | MenuType.PDF_VIEWER | MenuType.OFFICE_VIEWER,
        title: string,
        link: string | null,
        parentMenuCode: string | null,
        additionalConfig: object = {},
        uploadedFile: CustomReportFileUpload | null
    ) => {
        setTreeLoading(true);

        if(uploadedFile && uploadedFile.file && [MenuType.PDF_VIEWER, MenuType.OFFICE_VIEWER].includes(menuType)) {
            const responseUploadFile = await FileService.createTemporalFile({
                file: uploadedFile.file.originFileObj!,
                name: uploadedFile.name,
                maxSizeInBytes: MenuType.OFFICE_VIEWER ? REPORT_PDF_MAX_SIZE : REPORT_OFFICE_MAX_SIZE
            });

            if(responseUploadFile.success) {
                const responseUploadFileData = responseUploadFile.data as CreateTemporalFileResponseDto;
                additionalConfig = {
                    file: responseUploadFileData.s3Key,
                    size: responseUploadFileData.size
                };

            }else {
                const error = responseUploadFile.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar cargar el archivo al servidor.', 3.5);
                setTreeLoading(false);
                return;
            }
        }

        const response = await MenuService.createForCurrentCompany({
            title,
            icon: null,
            menuType,
            parentMenuCode: parentMenuCode,
            redirectLink: link,
            additionalConfig
        });

        if(response.success) {
            await fetchDataFromServer();
            messageApi.info(`Se añadió el reporte de manera satisfactoria.`);
            handleCloseNewReportMenuModal();
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar añadir el reporte.', 3.5);
        }

        setTreeLoading(false);
    }

    const removeNode = async (code: string, title: string, isContainer: boolean = false) => {
        modal.confirm({
            title: 'Confirmación',
            icon: <ExclamationCircleOutlined />,
            content: <span>¿Estás seguro que deseas remover el {isContainer ? 'contenedor' : 'reporte'} con título <b>{title}</b>?</span>,
            okText: 'SI',
            cancelText: 'NO',
            onOk: async () => {
                setTreeLoading(true);
                const response = await MenuService.delete(code);

                if(response.success) {
                    await fetchDataFromServer();
                    messageApi.success(<span>Se removió el {isContainer ? 'contenedor' : 'reporte'} de manera satisfactoria..</span>)
                }else {
                    const error = response.data as ApiErrorData;
                    messageApi.error(error.message as string || `Hubo un error al intentar remover el ${isContainer ? 'contenedor' : 'reporte'}, por favor inténtalo nuevamente.`, 3.5);
                }

                setTreeLoading(false);
            }
        });
    }

    const getDropdownMenu = (item: TreeDataNode): MenuProps['items'] => {
        const menus: MenuProps['items'] = [];
        let itemNro = 1;
        const node = dataSource.find((record) => {
            return record.code === item.key;
        });

        if(node) {
            const parentNode = dataSource.find((record) => {
                return record.code === node.parentMenuCode;
            });

            if(node.menuTypeId === MenuType.PARENT_MENU) {
                menus.push({
                    key: `${itemNro++}`,
                    label: <span><AreaChartOutlined /> Añadir reporte</span>,
                    onClick: () => { openNewReportMenuModal(node); }
                });

                if(node.companyOwnerId === authUser.company.id) {
                    menus.push({
                        key: `${itemNro++}`,
                        label: <span><EyeOutlined /> Detalles</span>,
                        onClick: () => { openDetailParentMenuModal(node); }
                    });

                    menus.push({
                        key: `${itemNro++}`,
                        label: <span><DeleteOutlined /> Remover contenedor</span>,
                        danger: true,
                        onClick: () => { removeNode(node.code, node.title, true); }
                    });
                }
            }

            if([MenuType.IFRAME_VIEWER, MenuType.POWER_BI_REPORT, MenuType.PDF_VIEWER, MenuType.OFFICE_VIEWER].includes(node.menuTypeId)  && node.companyOwnerId === authUser.company.id) {
                menus.push({
                    key: `${itemNro++}`,
                    label: <span><EyeOutlined /> Detalles</span>,
                    onClick: () => { openDetailReportMenuModal(node, parentNode?.title || ''); }
                });

                menus.push({
                    key: `${itemNro++}`,
                    label: <span><DeleteOutlined /> Remover reporte</span>,
                    danger: true,
                    onClick: () => { removeNode(node.code, node.title, false); }
                });
            }
        }else if(item.key === ROOT_CODE) {
            menus.push({
                key: `${itemNro++}`,
                label: <span><FolderOutlined /> Añadir contenedor</span>,
                onClick: () => { openNewParentMenuModal(); }
            });

            menus.push({
                key: `${itemNro++}`,
                label: <span><AreaChartOutlined /> Añadir reporte</span>,
                onClick: () => { openNewReportMenuModal(null); }
            });
        }

        if(menus.length === 0 ) {
            return [];
        }

        menus.unshift({
            key: `${itemNro++}`,
            type: 'divider'
        });

        return [
            {
                key: `${itemNro++}`,
                type: 'group',
                label: `Opciones disponibles`,
                children: menus
            }
        ];
    }

    const fetchDataFromServer = async (withLoading: boolean = false) => {
        if(withLoading) {
            setTreeLoading(true);
        }
        const response = await MenuService.getByCurrentCompany();

        if(response.success) {
            const dataTree = response.data as Menu[];
            setDataSource(dataTree);
            setTreeDataSource([
                {
                    ...INIT_TREE_ROOT,
                    children: buildTree(dataTree, authUser.company.id, customConfig.theme.token?.colorPrimary)
                }
            ]);
            setTreeBackupDataSource([
                {
                    ...INIT_TREE_ROOT,
                    children: buildTree(dataTree, authUser.company.id, customConfig.theme.token?.colorPrimary)
                }
            ]);
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de los menus de la compañía.', 3.5);
        }

        if(withLoading) {
            setTreeLoading(false);
        }
    }

    const onDrop: TreeProps['onDrop'] = (info) => {
        const { validate: checkCanDrop, dragNodeCode, newParentCode,  } = canDrop(info);

        if(!checkCanDrop) {
            return;
        }

        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (
            data: TreeDataNode[],
            key: React.Key,
            callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void,
        ) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children!, key, callback);
                }
            }
        };
        const data = [...treeDataSource];

        let dragObj: TreeDataNode;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                item.children.unshift(dragObj);
            });
        } else {
            let ar: TreeDataNode[] = [];
            let i: number;
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i!, 0, dragObj!);
            } else {
                ar.splice(i! + 1, 0, dragObj!);
            }
        }

        setTreeDataSource(data);

        updateTree(dragNodeCode, newParentCode, flattenWithOrder(data));
    };

    const updateTree = async (currentDragNode: string, parentCode: string | null, treeData: FlattenedNode[]) => {
        setDragEnable(false);

        const body: ReorganizeCompanyMenusRequestDto = {
            currentMenu: {
                code: currentDragNode,
                parentMenuCode: parentCode
            },
            menus: treeData
                .filter((record) => {
                    return record.parentCode === parentCode
                }).map((record) => {
                    return {
                        code: record.code as string,
                        order: record.order
                    }
                })
        };

        const response = await MenuService.reorganizeCompanyMenus(body);

        if(!response.success) {
            const error = response.data as ApiErrorData;
            const errorMessage = error.message as string || 'Hubo un error al intentar actualizar el orden de los menus.';
            console.error(errorMessage)
            setTreeDataSource(treeBackupDataSource);
        }else {
            console.log("Tree updated");
        }

        setDragEnable(true);
    }

    const onDragEnter: TreeProps['onDragEnter'] = (info) => {
        // console.log(info);
    };

    const canDrop = (info: any): { validate: boolean, dragNodeCode: string, newParentCode: string | null } => {
        const { dragNode, node: dropNode } = info;

        if(dragNode.key === ROOT_CODE) {
            return {
                validate: false,
                dragNodeCode: dragNode.key as string,
                newParentCode: null
            };
        }

        if(dropNode.key === ROOT_CODE && !dropNode.dragOver) {
            return {
                validate: false,
                dragNodeCode: dragNode.key as string,
                newParentCode: null
            };
        }

        const currentNode = dataSource.find((record) => {
            return record.code === dragNode.key;
        });

        if(!currentNode) {
            return {
                validate: false,
                dragNodeCode: dragNode.key as string,
                newParentCode: null
            };
        }

        let parent: Menu | null = null;

        if(dropNode.dragOver) {
            const parentNode = dataSource.find((record) => {
                return record.code === dropNode.key;
            });

            parent = parentNode || null;
        }else {
            const dropNodeData = dataSource.find((record) => {
                return record.code === dropNode.key;
            });

            const parentNode = dataSource.find((record) => {
                return record.code === dropNodeData?.parentMenuCode;
            });

            if(parentNode) {
                parent = parentNode;
            }
        }

        if(currentNode.menuTypeId === 'PARENT_MENU' && parent !== null) {
            return {
                validate: false,
                dragNodeCode: dragNode.key as string,
                newParentCode: null
            };
        }

        if(currentNode.menuTypeId !== 'PARENT_MENU' && parent !== null && parent.menuTypeId !== 'PARENT_MENU') {
            return {
                validate: false,
                dragNodeCode: dragNode.key as string,
                newParentCode: null
            };
        }

        return {
            validate: true,
            dragNodeCode: dragNode.key as string,
            newParentCode: parent ? parent.code : null
        };
    };

    const flattenWithOrder = (data: TreeDataNode[]): FlattenedNode[] => {
        const result: FlattenedNode[] = [];

        const traverse = (nodes: TreeDataNode[], parentBreadcrumb: string = "", parentKey: React.Key | null = null) => {
            nodes.forEach((node, index) => {
                const currentBreadcrumb = parentBreadcrumb
                    ? `${parentBreadcrumb}-${index + 1}`
                    : `${index + 1}`;

                const name = (typeof node.title === 'string')
                    ? node.title
                    : (React.isValidElement(node.title) ? node.title.props.children : "");

                result.push({
                    code: node.key,
                    name: name,
                    breadcrumb: currentBreadcrumb,
                    order: index + 1,
                    parentCode: parentKey === ROOT_CODE ? null : parentKey,
                });

                if (node.children && node.children.length > 0) {
                    traverse(node.children, currentBreadcrumb, node.key);
                }
            });
        };

        traverse(data);

        return result.filter((record) => record.code !== ROOT_CODE);
    };

    return (
        <>
            { contextHolder }
            { contextModalHolder }

            <Layout breadcrumb={[
                { title: 'Ajustes' },
                { title: 'Reportes y menus' },
            ]}>
                <Card loading={loading}>
                    <Tree
                        disabled={treeLoading}
                        selectable={false}
                        defaultExpandAll
                        treeData={treeDataSource}
                        showIcon
                        showLine
                        blockNode
                        draggable
                        onDragEnter={onDragEnter}
                        onDrop={onDrop}
                        titleRender={(item) => (
                            <Dropdown menu={ { items: getDropdownMenu(item) } } placement="bottomLeft" trigger={['contextMenu']}>
                                <span>{item.title as string}</span>
                            </Dropdown>
                        )}
                        allowDrop={(node) => {
                            return (dragEnable && node.dragNode.key !== ROOT_CODE);
                        }}
                    />
                </Card>

                {/* Modals */}
                <NewContainerModal
                    isOpen={isShowNewParentMenuModal}
                    handleModalCancel={handleCloseNewParentMenuModal}
                    submit={createNewParentMenu}
                />

                <NewReportModal
                    selectedNode={selectedNode || null}
                    isOpen={isShowNewReportMenuModal}
                    handleModalCancel={handleCloseNewReportMenuModal}
                    submit={createNewReportMenu}
                />

                {
                    selectedNode && (
                        <DetailContainerModal
                            code={selectedNode.code}
                            isOpen={isShowDetailParentMenuModal}
                            handleModalCancel={handleCloseDetailParentMenuModal}
                            submit={updateParentMenu}
                        />
                    )
                }

                {
                    selectedNode && (
                        <DetailReportModal
                            code={selectedNode.code}
                            parentName={parentTitle}
                            isOpen={isShowDetailReportMenuModal}
                            handleModalCancel={handleCloseDetailReportMenuModal}
                            submit={updateReportMenu}/>
                    )
                }
            </Layout>
        </>
    );
}

export default ReportAndMenus;
