import React, {useEffect, useState} from 'react';
import {Alert, Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import {Menu} from "../../../../models/Menu";
import TextArea from "antd/es/input/TextArea";
import {MenuService} from "../../../../services/MenuService";
import {ApiErrorData} from "../../../../models/ApiResponse";
import {FolderOpenOutlined} from "@ant-design/icons";
import {REPORT_TYPES_ITEMS} from "../../../../utils/Constants";
import {MAIN_BACKEND_URL, MenuType} from "../../../../config/Config";
import ReportFileUploader, {CustomReportFileUpload} from "./ReportFileUploader";
import {FileHelper} from "../../../../utils/FileHelper";
import {REPORT_OFFICE_MAX_SIZE, REPORT_PDF_MAX_SIZE} from "./NewReportModal";

interface NewReportModalProps {
    code: string;
    parentName: string;
    isOpen: boolean;
    handleModalCancel: () => void;
    submit: (
        code: string,
        menuType: MenuType.IFRAME_VIEWER | MenuType.POWER_BI_REPORT | MenuType.PDF_VIEWER | MenuType.OFFICE_VIEWER,
        name: string,
        link: string | null,
        additionalConfig: object,
        uploadFile: CustomReportFileUpload | null
    ) => void;
}

function NewReportModal({isOpen, handleModalCancel, submit, parentName, code}: NewReportModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState<MenuType.IFRAME_VIEWER | MenuType.POWER_BI_REPORT | MenuType.PDF_VIEWER | MenuType.OFFICE_VIEWER>();
    const [pdfUploadFile, setPdfUploadFile] = useState<CustomReportFileUpload | null>(null);
    const [officeUploadFile, setOfficeUploadFile] = useState<CustomReportFileUpload | null>(null);
    const [additionalConfigBackup, setAdditionalConfigBackup] = useState<any>({});

    useEffect(() => {
        form.resetFields();
        setSelectedType(undefined);
        setAdditionalConfigBackup({});

        if(isOpen) {
            fetchData();
        }else {
            setLoading(false);
        }

    }, [isOpen]);

    useEffect(() => {
        form.setFieldValue('showFilterPane', true);
        form.setFieldValue('showPageNavigation', true);
        form.setFieldValue('link', '');
        form.setFieldValue('reportId', '');
        form.setFieldValue('groupId', '');
        setPdfUploadFile(null);
        setOfficeUploadFile(null);
    }, [selectedType]);

    const onFinish = async (values: any) => {
        if(selectedType) {
            setLoading(true);

            await submit(
                code,
                selectedType,
                values.title.trim(),
                values.link ? values.link : null,
                selectedType === MenuType.POWER_BI_REPORT
                    ? { settings: { showFilterPane: !!values.showFilterPane, showPageNavigation: !!values.showPageNavigation }, groupId: values.groupId, reportId: values.reportId }
                    :  [MenuType.OFFICE_VIEWER, MenuType.PDF_VIEWER].includes(selectedType) ? additionalConfigBackup : {},
                selectedType === MenuType.OFFICE_VIEWER
                    ? officeUploadFile
                    : selectedType === MenuType.PDF_VIEWER
                        ? pdfUploadFile
                        : null
        );

            setLoading(false);
        }
    }

    const fetchData = async () => {
        setLoading(true);

        const response = await MenuService.findByCode(code);

        if(response.success) {
            const data = response.data as Menu;
            const additionalConfig = data.additionalConfig;

            if(data.menuTypeId === MenuType.POWER_BI_REPORT) {
                setSelectedType(MenuType.POWER_BI_REPORT);
                form.setFieldValue('type', MenuType.POWER_BI_REPORT);
            } else if(data.menuTypeId === MenuType.PDF_VIEWER) {
                setSelectedType(MenuType.PDF_VIEWER);
                form.setFieldValue('type', MenuType.PDF_VIEWER);
            } else if(data.menuTypeId === MenuType.OFFICE_VIEWER) {
                setSelectedType(MenuType.OFFICE_VIEWER);
                form.setFieldValue('type', MenuType.OFFICE_VIEWER);
            } else {
                setSelectedType(MenuType.IFRAME_VIEWER);
                form.setFieldValue('type', MenuType.IFRAME_VIEWER);
            }

            setTimeout(() => {
                form.setFieldValue('title', data.title);
                form.setFieldValue('link', data.redirectLink || '');

                 let reportId: string = '';
                 let groupId: string = '';
                 let showFilterPane: boolean = true;
                 let showPageNavigation: boolean = true;

                setAdditionalConfigBackup({});

                if(data.menuTypeId === MenuType.POWER_BI_REPORT) {
                    reportId = `${additionalConfig.reportId}` || '';
                    groupId = `${additionalConfig.groupId}` || '';
                    showFilterPane = !!additionalConfig.settings?.showFilterPane;
                    showPageNavigation = !!additionalConfig.settings?.showPageNavigation;
                } else if(data.menuTypeId === MenuType.PDF_VIEWER) {
                    const file = `${additionalConfig.file}` || '';
                    const size = parseInt(`${additionalConfig.size}`) || 0;
                    setPdfUploadFile({
                        name: FileHelper.getFilenameFromS3Key(file),
                        url: `${MAIN_BACKEND_URL}/file/get?download=true&key=${file}`,
                        size
                    });
                    setAdditionalConfigBackup({
                        file,
                        size
                    });
                } else if(data.menuTypeId === MenuType.OFFICE_VIEWER) {
                    const file = `${additionalConfig.file}` || '';
                    const size = parseInt(`${additionalConfig.size}`) || 0;
                    setOfficeUploadFile({
                        name: FileHelper.getFilenameFromS3Key(file),
                        url: `${MAIN_BACKEND_URL}/file/get?download=true&key=${file}`,
                        size
                    });
                    setAdditionalConfigBackup({
                        file,
                        size
                    });
                }

                form.setFieldValue('reportId', reportId);
                form.setFieldValue('groupId', groupId);
                form.setFieldValue('showFilterPane', showFilterPane);
                form.setFieldValue('showPageNavigation', showPageNavigation);
            }, 125);

            setLoading(false);
        }else {
            const error = response.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos del rol, por favor inténtalo nuevamente.', 3.5);
            handleModalCancel();
        }

        setLoading(false);
    }

    return (
        <>
            {contextHolder}

            <Modal
                title={`Detallles del reporte`}
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={480}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24} style={{ marginBottom: '18px' }}>
                        <Col xs={24}>
                            <Alert message="Al generarse el nuevo reporte se añadirá directamente al contenedor padre." type="info" showIcon />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Contenedor padre"
                            >
                                <Input type="text" addonBefore={<FolderOpenOutlined />} placeholder="Contenedor" readOnly defaultValue={parentName}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider style={{ marginTop: '0', marginBottom: '15px' }}/>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="title"
                                label="Título"
                                rules={[{ required: true, message: 'Debes de ingresar el título' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el título"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="type"
                                label="Tipo de reporte"
                                rules={[{ required: true, message: 'Debes de seleccionar una opción' }]}
                            >
                                <Select
                                    placeholder="Seleccione una opción"
                                    options={REPORT_TYPES_ITEMS}
                                    allowClear={true}
                                    onChange={(newValue) => {
                                        setSelectedType(newValue);
                                    }}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        selectedType === MenuType.IFRAME_VIEWER && (
                            <Row gutter={24}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        name="link"
                                        label="Enlace url"
                                        rules={[
                                            { required: true, message: 'Debes de ingresar el enlace url' },
                                            {
                                                validator: (_, value) => {
                                                    if (!value) return Promise.resolve();
                                                    const urlRegex = /^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w-.]*)*(\?.*)?(#.*)?$/;
                                                    if (!urlRegex.test(value)) {
                                                        return Promise.reject(new Error('Debes ingresar un enlace válido que comience con http:// o https://'));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            placeholder="Ingrese el enlace url del reporte"
                                            rows={4}
                                            disabled={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    }

                    {
                        selectedType === MenuType.POWER_BI_REPORT && (
                            <>
                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="reportId"
                                            label="ID del reporte"
                                            rules={[{ required: true, message: 'Debes de ingresar el ID del reporte' }]}
                                        >
                                            <Input type="text" placeholder="Ingrese el ID del reporte" disabled={loading}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="groupId"
                                            label="ID del grupo o espacio de trabajo"
                                            rules={[{ required: true, message: 'Debes de ingresar el ID del grupo o espacio de trabajo' }]}
                                        >
                                            <Input type="text" placeholder="Ingrese el ID del grupo o espacio de trabajo" disabled={loading}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="showFilterPane"
                                            valuePropName="checked"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Checkbox disabled={loading}>Deseo mostrar el panel de filtros del reporte.</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="showPageNavigation"
                                            valuePropName="checked"
                                        >
                                            <Checkbox disabled={loading}>Deseo habilitar la navegación entre páginas del reporte.</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )
                    }

                    {
                        selectedType === MenuType.PDF_VIEWER && (
                            <>
                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="pdfFile"
                                            label="Archivo"
                                            rules={[
                                                {
                                                    required: true,
                                                    validator: (_, value) => {
                                                        if (pdfUploadFile) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Debes cargar un archivo.'));
                                                    },
                                                }
                                            ]}
                                        >
                                            <ReportFileUploader
                                                renameFile
                                                allowedTypes={[
                                                    'application/pdf'
                                                ]}
                                                allowedTypeErrorMessage="Solo se permiten archivos de tipo pdf."
                                                helpFileText={`Máximo de ${FileHelper.formatFileSize(REPORT_PDF_MAX_SIZE, true)}. Solo se permiten archivos de tipo pdf.`}
                                                maxSizeInBytes={REPORT_PDF_MAX_SIZE}
                                                uploadedFile={pdfUploadFile}
                                                setUploadFile={setPdfUploadFile}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )
                    }

                    {
                        selectedType === MenuType.OFFICE_VIEWER && (
                            <>
                                <Row gutter={24}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="officeFile"
                                            label="Archivo"
                                            rules={[
                                                {
                                                    required: true,
                                                    validator: (_, value) => {
                                                        if (officeUploadFile) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Debes cargar un archivo.'));
                                                    },
                                                }
                                            ]}
                                        >
                                            <ReportFileUploader
                                                renameFile
                                                allowedTypes={[
                                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                    'application/vnd.ms-excel',
                                                    'application/msword',
                                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                    'application/vnd.ms-powerpoint',
                                                    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                                                ]}
                                                allowedTypeErrorMessage="Solo se permiten archivos de tipo xlsx, xls, doc, docx, ppt o pptx."
                                                helpFileText={`Máximo de ${FileHelper.formatFileSize(REPORT_OFFICE_MAX_SIZE, true)}. Solo se permiten archivos de tipo xlsx, xls, doc, docx, ppt o pptx.`}
                                                maxSizeInBytes={REPORT_OFFICE_MAX_SIZE}
                                                uploadedFile={officeUploadFile}
                                                setUploadFile={setOfficeUploadFile}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )
                    }

                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewReportModal;
