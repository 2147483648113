import React from 'react';
import Icon, * as AntIcons from "@ant-design/icons";
import { ReactComponent as BukSvg } from '../../assets/icons/buk_icon.svg';
import { ReactComponent as GeoVictoriaSvg } from '../../assets/icons/geovictoria_icon.svg';

export interface DynamicIconProps {
    type: string | null;
    iconProps?: React.ComponentProps<typeof Icon>;
}

const BukIcon = (props: React.ComponentProps<typeof Icon>) => (
    <Icon component={BukSvg} {...props} />
);

const GeoVictoriaIcon = (props: React.ComponentProps<typeof Icon>) => (
    <Icon component={GeoVictoriaSvg} {...props} />
);

export const icons: Record<string, React.ReactElement> = {
    ...Object.keys(AntIcons).reduce((acc, key) => {
        if (key.endsWith('Outlined') || key.endsWith('Filled') || key.endsWith('TwoTone')) {
            acc[key] = React.createElement((AntIcons as any)[key]);
        }
        return acc;
    }, {} as Record<string, React.ReactElement>),
    'Buk': <BukIcon />,
    'GeoVictoria': <GeoVictoriaIcon />
};

/*
* Ejemplo:
* <DynamicIcon type="HomeOutlined" style={{ fontSize: '24px', color: 'blue' }} />
* */
function DynamicIcon({ type, iconProps }: DynamicIconProps) {
    if (!type || !icons[type]) {
        return icons['WarningOutlined'];
    }
    return React.cloneElement(icons[type], iconProps);
}

export default DynamicIcon;
