import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {User} from "../../models/User";
import {MenuType} from "../../config/Config";
import {FunctionsHelper} from "../../utils/FunctionsHelper";
import Layout from "../../components/Layout/Layout";
import {ItemType} from "antd/es/breadcrumb/Breadcrumb";
import DynamicIcon from "../../components/DynamicIcon/DynamicIcon";
import {Button, Card, message, Tooltip} from "antd";
import {PowerBiAdditionalConfig} from "../../models/PowerBiAdditionalConfig";
import {PowerBiReportService} from "../../services/PowerBiReportService";
import {PowerBiReportData} from "../../models/PowerBiReportData";
import {ApiErrorData} from "../../models/ApiResponse";
import {PowerBIEmbed} from "powerbi-client-react";
import {models} from "powerbi-client";
import {FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";

import './PowerBiReport.scss';

function PowerBiReport() {
    const { key }: any = useParams();
    const [messageApi, contextHolder] = message.useMessage();
    const user: User = useSelector((state: any) => state.auth);
    const [title, setTitle] = useState("");
    const [breadcrumb, setBreadcrumb] = useState<ItemType[]>([]);

    const [embedConfig, setEmbedConfig] = useState<any>(null);
    const powerBIContainerRef = useRef<HTMLDivElement>(null);
    const [isFullscreen, setIsFullscreen] = useState(false);

    /* Settings */
    const heightOffset = 164 + 36;
    const heightPadding = 48;
    const vh = window.innerHeight;
    const vw = window.innerWidth;

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(document.fullscreenElement !== null);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('msfullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('msfullscreenchange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        init();
    }, [key]);

    const init = async () => {
        const localBreadcrumb: ItemType[] = [];
        const availableData = FunctionsHelper.flattenMenuArray(user.allowedMenus).find((menu) => {
            return menu.code === key && menu.menuTypeId === MenuType.POWER_BI_REPORT;
        });

        if (availableData) {
            const additionalConfig: PowerBiAdditionalConfig = availableData.additionalConfig;

            if(!(additionalConfig.reportId && additionalConfig.groupId)) {
                console.error("Error, no se encontró los datos adicionales requeridos", additionalConfig)
                //TODO: error
            }

            for(const menu of user.allowedMenus) {
                if(menu.menus) {
                    for(const menuRow of menu.menus) {
                        if(menuRow.code === key){
                            localBreadcrumb.push({
                                title: <span>{menu.icon ? (menu.icon) : <DynamicIcon type={'SwitcherOutlined'}/>} {menu.title}</span>,
                            });
                            break;
                        }
                    }
                }
            }

            localBreadcrumb.push({
                title: <span>{availableData.icon && (availableData.icon)} {availableData.title}</span>
            });

            setTitle(availableData.title);
            setBreadcrumb(localBreadcrumb);

            const reportDataResponse = await PowerBiReportService.getFullDataForReport(additionalConfig);

            if(reportDataResponse.success) {
                const { embedToken, embedUrl, id, webUrl } = reportDataResponse.data as PowerBiReportData;

                setEmbedConfig({
                    type: 'report',
                    id,
                    embedUrl,
                    accessToken: embedToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: { visible: !!additionalConfig.settings?.showFilterPane },
                            pageNavigation: { visible: !!additionalConfig.settings?.showPageNavigation },
                        },
                    },
                });

            }else {
                const error = reportDataResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos del reporte, por favor inténtalo nuevamente.', 3.5);
            }
        } else {
            setTitle("");
            setBreadcrumb([]);
            setEmbedConfig(null);
        }
    }

    const handleFullscreen = () => {
        const container = powerBIContainerRef.current;
        if (container) {
            if (!isFullscreen) {
                container.requestFullscreen();
                setIsFullscreen(true);
            } else {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    return (
        <>
            {contextHolder}
            <Layout title={title} breadcrumb={breadcrumb}>
                <Card style={{
                    width: '100%',
                    height: isFullscreen ? '100vh' : `${vh - heightOffset}px`,
                    position: 'relative' // Para posicionar el botón absolutamente
                }}
                      className="power-bi-card-container"
                      loading={!embedConfig}
                >
                    <div ref={powerBIContainerRef} style={{ width: '100%', height: '100%' }}>
                        {embedConfig ? (
                            <PowerBIEmbed
                                embedConfig={embedConfig}
                                cssClassName="powerbi-container"
                            />
                        ) : (
                            <p>Cargando reporte...</p>
                        )}
                    </div>

                    {
                        embedConfig && (
                            <Tooltip title={isFullscreen ? "Salir de Pantalla Completa" : "Pantalla Completa"}>
                                <Button
                                    type="primary"
                                    icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                                    onClick={handleFullscreen}
                                    style={{
                                        position: 'absolute',
                                        bottom: '16px', // Cambia `top` por `bottom` para que se posicione en la parte inferior
                                        right: '16px', // Mantén `right` para que se alinee a la derecha
                                        zIndex: 10, // Asegura que el botón esté por encima del iframe
                                        opacity: 0.7, // Opacidad inicial
                                        transition: 'opacity 0.3s ease', // Transición para la opacidad
                                    }}
                                    onMouseEnter={() => {
                                        const button: any = document.querySelector('.fullscreen-button');
                                        if (button) {
                                            button.style.opacity = 1;
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        const button: any = document.querySelector('.fullscreen-button');
                                        if (button) {
                                            button.style.opacity = 0.7;
                                        }
                                    }}
                                    className="fullscreen-button"
                                />
                            </Tooltip>
                        )
                    }
                </Card>
            </Layout>
        </>
    );
}

export default PowerBiReport;
