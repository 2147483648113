import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Dropdown, MenuProps, Modal, Space} from "antd";
import {User} from "../../../models/User";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {AuthService} from "../../../services/AuthService";
import AuthAction from "../../../redux/actions/authActions/Actions";
import {useThemeLayoutConfig} from "../../../config/ThemeDashboardLayoutConfig";
import { LogOut, Settings, User as UserIcon, HelpCircle } from 'lucide-react';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {USER_TYPES} from "../../../config/Constants";

import './ProfileMenu.scss';
import ConfigSettings, {ConfigSettingsHandle} from "../ConfigSettings";

interface ProfileMenuProps {
    loading: boolean;
    setLoading: (value: boolean) => void;
}

function ProfileMenu({ loading, setLoading }: ProfileMenuProps) {
    const [modal, contextModalHolder] = Modal.useModal();
    const customConfig = useThemeLayoutConfig();
    const authUser: User = useSelector((state: any) => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const [items, setItem] = useState<MenuProps['items']>([]);
    const configSettingsRef = useRef<ConfigSettingsHandle | null>(null);

    const openConfigDrawer = () => {
        if(configSettingsRef.current && authUser.userType === USER_TYPES.SUPER_ADMIN && !loading) {
            configSettingsRef.current.openDrawer();
        }
    };

    useEffect(() => {
        setItem([
            {
                key: 'header',
                label: (
                    <div className="user-header">
                        <div className="user-info-container">
                            <Avatar
                                style={{
                                    backgroundColor: customConfig.customValues.secondaryColor || '#cfe8fc',
                                    color: customConfig.theme.token?.colorPrimary || '#0077cc',
                                    cursor: 'pointer'
                                } }
                                size="large"
                            >
                                <span
                                    style={{
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    { authUser.name.slice(0, 1) + authUser.lastname.slice(0, 1) }
                                </span>
                            </Avatar>

                            <div>
                                <div className="user-name">{authUser.name}</div>
                                <div className="user-company">{authUser.company.name}</div>
                                <div className="user-email">{authUser.email}</div>
                            </div>
                        </div>
                    </div>
                ),
                type: 'group',
            },
            {
                type: 'divider',
            },
            {
                key: 'profile',
                label: 'Mi Perfil',
                icon: <UserIcon className="menu-icon" />,
                disabled: true
            },
            ...(authUser.userType === USER_TYPES.SUPER_ADMIN
                ? [
                    {
                        key: 'settings',
                        label: 'Configuración',
                        icon: <Settings className="menu-icon" />,
                        onClick: openConfigDrawer
                    },
                ]
                : []),
            {
                type: 'divider',
            },
            {
                key: 'logout',
                label: 'Cerrar Sesión',
                icon: <LogOut className="menu-icon" />,
                danger: true,
                onClick: handleLogout
            },
        ]);
    }, []);

    const handleLogout = () => {
        modal.confirm({
            title: 'Confirmación',
            icon: <ExclamationCircleOutlined />,
            content: <span>¿Estás seguro que deseas cerrar sesión?</span>,
            okText: 'SI',
            cancelText: 'NO',
            onOk: logout
        });
    }

    const logout = async () => {
        setLoading(true);

        if (authUser.token) {
            await AuthService.logout();
        }

        dispatch(AuthAction.signOut({}));
        setLoading(false);
    }

    return (
        <>
            <Space direction="vertical">
                {contextModalHolder}
                <Space wrap>
                    <Dropdown
                        menu={{ items }}
                        trigger={["click"]}
                        placement="bottomLeft"
                        arrow
                    >
                        <div onClick={e => e.preventDefault()}>
                            <Space size={16} wrap>
                                <Avatar
                                    style={{
                                        backgroundColor: customConfig.customValues.secondaryColor || '#cfe8fc',
                                        color: customConfig.theme.token?.colorPrimary || '#0077cc',
                                        cursor: 'pointer'
                                    } }
                                >
                                <span
                                    style={{
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    { authUser.name.slice(0, 1) + authUser.lastname.slice(0, 1) }
                                </span>
                                </Avatar>
                            </Space>
                        </div>
                    </Dropdown>
                </Space>
            </Space>

            <ConfigSettings ref={configSettingsRef} hideFloatButton/>
        </>
    );
}

export default ProfileMenu;
