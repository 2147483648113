import React, {useState} from 'react';
import {message, Upload} from "antd";
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { v4 as uuidv4 } from 'uuid';
import {FileHelper} from "../../../../utils/FileHelper";
import {
    DeleteOutlined,
    FileExcelOutlined,
    FileOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    UploadOutlined
} from "@ant-design/icons";

import './ReportFileUploader.scss';

export interface CustomReportFileUpload {
    url?: string;
    name: string;
    size: number;
    file?: UploadFile;
}

interface ReportFileUploaderProps {
    disabled?: boolean;
    allowedTypes?: string[];
    allowedTypeErrorMessage?: string;
    maxSizeInBytes?: number;
    renameFile?: boolean;
    helpFileText?: React.ReactNode | string;
    uploadedFile: CustomReportFileUpload | null;
    setUploadFile: (uploadedFile: CustomReportFileUpload | null) => void;
}

function ReportFileUploader({ allowedTypes = [], disabled = false, allowedTypeErrorMessage, maxSizeInBytes, renameFile = false, helpFileText, uploadedFile, setUploadFile }: ReportFileUploaderProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList.length === 0) {
            setUploadFile(null);
            return;
        }

        const file = newFileList[0];

        if (!(file && file.size && file.size > 0)) {
            messageApi.error('El archivo debe de tener contenido');
            return;
        }

        if(maxSizeInBytes && maxSizeInBytes > 0) {
            if (file && file.size && file.size > maxSizeInBytes) {
                messageApi.error(`El archivo no puede ser mayor a ${FileHelper.formatFileSize(maxSizeInBytes)}`);
                return;
            }
        }

        if(renameFile) {
            if (file && !file.url && !file.preview) {
                const extension = FileHelper.getFileExtension(file.name);
                file.name = `${uuidv4()}.${extension}`;
            }
        }

        setUploadFile({
            name: file.name,
            size: file.size,
            file: file
        });

        setFileList([ file ]);
    };

    const uploadProps: UploadProps = {
        beforeUpload: (file) => {
            const extension = FileHelper.getFileExtension(file.name).toLowerCase();
            const isAllowedType = allowedTypes!.length > 0 ? (allowedTypes.includes(extension) || allowedTypes.includes(file.type)) : true;

            if (!isAllowedType) {
                messageApi.error(allowedTypeErrorMessage || 'La extensión del archivo no está permitida.');
                return Upload.LIST_IGNORE;
            }

            return false;
        },
        fileList,
        onChange: handleChange,
        maxCount: 1,
        showUploadList: false
    };

    const handlePreview = () => {
        if(uploadedFile) {
            const file = uploadedFile.file;

            if (file && file.originFileObj) {
                const url = URL.createObjectURL(file.originFileObj);
                const a = document.createElement('a');
                a.href = url;
                a.download = file.name;
                a.click();
                URL.revokeObjectURL(url);
            }else if(uploadedFile.url) {
                const a = document.createElement('a');
                a.href = uploadedFile.url;
                a.download = uploadedFile.name;
                a.click();
                URL.revokeObjectURL(uploadedFile.url);
            }
        }
    };

    const handleRemove = () => {
        setFileList([]);
        setUploadFile(null);
    };

    const getFileIcon = () => {
        if(!uploadedFile) {
            return <FileOutlined className="custom-file-list-item-icon" />;
        }

        const extension = FileHelper.getFileExtension(uploadedFile.name).toLowerCase();

        switch (extension) {
            case 'xls':
                return <FileExcelOutlined className="custom-file-list-item-icon custom-file-list-item-icon-excel" />;
            case 'xlsx':
                return <FileExcelOutlined className="custom-file-list-item-icon custom-file-list-item-icon-excel" />;
            case 'doc':
                return <FileWordOutlined className="custom-file-list-item-icon" />;
            case 'docx':
                return <FileWordOutlined className="custom-file-list-item-icon" />;
            case 'pdf':
                return <FilePdfOutlined className="custom-file-list-item-icon custom-file-list-item-icon-pdf" />;
            default:
                return <FileOutlined className="custom-file-list-item-icon" />;
        }
    }

    return (
        <div className="custom-file-container">
            {contextHolder}
            <Upload.Dragger disabled={disabled} {...uploadProps} className="custom-file-dragger">
                <p className="custom-file-icon">
                    <UploadOutlined />
                </p>
                <p className="custom-file-text">Haz clic o arrastra un archivo aquí</p>
                {
                    helpFileText && (
                        <p className="custom-file-help-text">
                            {helpFileText}
                        </p>
                    )
                }
            </Upload.Dragger>

            {uploadedFile && (
                <div className="custom-file-list">
                    <div className="custom-file-list-item">
                        <div className="custom-file-list-item-info">
                            {getFileIcon()}
                            <span
                                className="custom-file-list-item-name"
                                onClick={() => handlePreview()}
                            >
                                  {uploadedFile.name}
                                </span>
                            <span className="custom-file-list-item-size">
                                  {FileHelper.formatFileSize(uploadedFile.size || 0, true)}
                                </span>
                        </div>
                        <div className="custom-file-list-item-actions">
                            <button
                                className="custom-file-list-item-action"
                                onClick={() => handleRemove()}
                                title="Eliminar archivo"
                            >
                                <DeleteOutlined />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReportFileUploader;
