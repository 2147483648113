import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import { message} from "antd";
import {ContactGrid} from "./components/ContactGrid";
import {Contact} from "../../models/Contact";
import {ContactService} from "../../services/ContactService";
import {ApiErrorData} from "../../models/ApiResponse";

import './ContactUs.scss';

function ContactUs() {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState<Contact[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const contactResponse = await ContactService.getAvailableByCurrentCompany();

        if(contactResponse.success) {
            setContacts(contactResponse.data as Contact[]);
        }else {
            const error = contactResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de los contactos, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    return (
        <Layout>
            {contextHolder}
            <ContactGrid loading={loading} contacts={contacts}/>
        </Layout>
    );
}

export default ContactUs;
